import { Component, OnInit } from '@angular/core';
import { Categoria, GlobalService, Result, Premio, Campagna } from '../services/global.service';
import { HttpErrorResponse, HttpRequest, HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { NzNotificationService, NzMessageService } from 'ng-zorro-antd';
import { ColorPickerService } from 'ngx-color-picker';
import { Observable, Observer } from 'rxjs';
import { UploadFile, UploadXHRArgs } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-premi',
  templateUrl: './premi.component.html',
  styleUrls: ['./premi.component.scss']
})
export class PremiComponent {
  // CONFIG
  dataCfg = {
    tableTitle: 'Tabella Premi',
    table: 'premi',
    key: 'id',
    checkDup: 'id',
  };

  // COMPONENT VARS
  public newItem: Premio = { attivo: false, prenotabile: true } as Premio;
  public newCodeError: string = 'validating';
  isLoading: boolean = true;

  // TABLE VARS
  categorie = [] as Categoria[];
  campagne = [] as Campagna[];
  dataSet = [];
  public displayData = [...this.dataSet];
  editCache = {};
  sortName = null;
  sortValue = null;

  loading = false;
  currentId = '';

  constructor(
    public g: GlobalService,
    private notification: NzNotificationService,
    private message: NzMessageService,
    private cpService: ColorPickerService,
    private http: HttpClient
  ) {
    // SETUP INTERFACE
    this.g.menuState = 3;
    this.g.homeTitle = this.dataCfg.tableTitle;

    // LOAD DATA
    this.loadCategorie();
  }
  // ################################################
  // LOAD CATEGORIE
  loadCategorie() {
    console.log('LOAD CATEGORIE: loading');
    this.isLoading = true;
    const query: string = 'SELECT * from categorie';
    this.g.mysql(query)
      .subscribe((res: Result) => {
        this.isLoading = false;
        console.log('LOAD CATEGORIE: result', res.data);

        this.categorie = res.data;
        this.loadCampagne();
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notification.error('Si è vericato un errore non previsto', error.statusText, { nzDuration: 0 });
      });
  }
  // ################################################
  // LOAD CAMPAGNE
  loadCampagne() {
    console.log('LOAD CAMPAGNE: loading');
    this.isLoading = true;
    const query: string = 'SELECT * from campagne';
    this.g.mysql(query)
      .subscribe((res: Result) => {
        this.isLoading = false;
        console.log('LOAD CAMPAGNE: result', res.data);

        this.campagne = res.data;
        this.loadData();
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notification.error('Si è vericato un errore non previsto', error.statusText, { nzDuration: 0 });
      });
  }

  // ################################################
  // LOAD DATA
  loadData() {
    console.log('LOAD DATA: loading');
    this.isLoading = true;
    // tslint:disable-next-line:max-line-length
    const query: string = 'SELECT premi.*, categorie.titolo As categoria, campagne.titolo As campgna from premi Inner Join campagne On campagne.id = premi.id_campagna Inner Join categorie On categorie.id = premi.id_categoria';
    this.g.mysql(query)
      .subscribe((res: Result) => {
        this.isLoading = false;
        this.dataSet = res.data;
        this.dataSet.forEach(item => {
          if ( item.immagine ) { item.immagine += '?random+\=' + Math.random(); }
        });
        this.updateEditCache();
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notification.error('Si è vericato un errore non previsto', error.statusText, { nzDuration: 0 });
      });
  }
  // ################################################
  // UPDATE EDIT CACHE
  updateEditCache(): void {
    if (this.dataSet != null) {
      this.dataSet.forEach(item => {
        const campagna = this.campagne.find(c => c.id === item.id_campagna);
        const categoria = this.categorie.find(c => c.id === item.id_categoria);
        item['disabled'] = !campagna.attiva;
        item['cat_color'] = categoria.colore;
        if (!this.editCache[item.id]) {
          this.editCache[item.id] = {
            edit: false,
            loading: false,
            data: item
          };
        }
      });
    }
    // this.displayData = [...this.dataSet];
    this.sort(null);
  }
  // ################################################
  // START EDIT
  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }
  // ################################################
  // CANCEL EDIT
  cancelEdit(id: string): void {
    this.editCache[id].edit = false;
  }
  // ################################################
  // SAVE NEW
  saveNew(): void {
    const check = this.dataSet.findIndex(item => item[this.dataCfg.checkDup] === this.newItem[this.dataCfg.checkDup]);
    if (check !== -1) {
      this.notification.error('Record già presente',
        'Attenzione: il record inserito è già presente nell\'archivio [' + this.dataCfg.checkDup + '].', { nzDuration: this.g.fastTime });
      this.newCodeError = 'error';
      return;
    }

    // ############################### CUSTOMIZE
    const query: string = 'INSERT INTO ' + this.dataCfg.table +
      // tslint:disable-next-line:max-line-length
      ' ( `codice`, `titolo`, `id_campagna`, `id_categoria`, `punti`, `punti_contributo`, `contributo`, `ean`, `ean_contributo`, `attivo`, `prenotabile`) VALUES (' +
      '"' + this.newItem.codice + '",' +
      '"' + this.newItem.titolo + '",' +
      '"' + this.newItem.id_campagna + '",' +
      '"' + this.newItem.id_categoria + '",' +
      '"' + (this.newItem.punti | 0) + '",' +
      '"' + (this.newItem.punti_contributo | 0) + '",' +
      '"' + (this.newItem.contributo | 0) + '",' +
      '"' + (this.newItem.ean || '') + '",' +
      '"' + (this.newItem.ean_contributo || '') + '",' +
      false + ',' + // ATTIVO
      this.newItem.prenotabile + ')';
    console.log(query);
    // ###############################

    this.isLoading = true;
    this.g.mysql(query)
      .subscribe((res: Result) => {
        this.isLoading = false;
        if (res.result === false) {
          this.newItem[this.dataCfg.key] = res.data.insertId;
          this.dataSet.push(this.newItem);

          this.newItem = { attivo: false, prenotabile: false } as Premio;
          this.newCodeError = 'validating';

          this.message.success('Nuovo record creato con successo!');
          this.updateEditCache();
        } else {
          this.notification.error('Si è verificato un problema, si prega di riprovare tra qualche minuto.',
            res.message, { nzDuration: this.g.normalTime });
          console.log(res);
        }
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notification.error('Si è vericato un errore non previsto.', error.statusText, { nzDuration: 0 });
      });
  }
  // ##############################################
  // SORT
  sort(sort: { key: string, value: string }): void {
    const sortBy = [{
      prop: 'disabled',
      direction: 1
    }];

    if (sort && sort.key) {
      sortBy.push({
        prop: sort.key,
        direction: (sort.value === 'ascend') ? 1 : -1
      });
    } else {
      sortBy.push({
        prop: 'codice',
        direction: 1
      });
    }

    console.log('SORT', sortBy);

    this.displayData = [...this.dataSet.sort((a, b) => {
      let i = 0; let result = 0;
      while (i < sortBy.length && result === 0) {
        if (sortBy[i].prop === 'codice') {
          // tslint:disable-next-line:max-line-length
          result = sortBy[i].direction * (+a[sortBy[i].prop] < +b[sortBy[i].prop] ? -1 : (+a[sortBy[i].prop] > +b[sortBy[i].prop] ? 1 : 0));
        } else {
          // tslint:disable-next-line:max-line-length
          result = sortBy[i].direction * (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString() ? -1 : (a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString() ? 1 : 0));
        }
        i++;
      }
      return result;
    })];
  }
  // ##############################################
  // SAVE EDIT
  saveEdit(id: string): void {
    const data: Premio = this.editCache[id].data;
    const index = this.dataSet.findIndex(item => item.id === id);

    const check = this.dataSet.findIndex(item => item.titolo === data.titolo);
    if (check !== -1 && check !== index) {
      this.notification.error('Record già presente',
        'Attenzione: il record inserito è già presente nell\'archivio [' + this.dataCfg.checkDup + '].', { nzDuration: this.g.fastTime });
      return;
    }

    // ********************************* CUSTOMIZE
    this.dataSet[index] = this.editCache[id].data;
    this.editCache[id].edit = false;
    const query: string = 'UPDATE ' + this.dataCfg.table +
      ' SET `codice`="' + data.codice +
      '", `titolo`="' + data.titolo +
      '", `punti`="' + data.punti +
      '", `punti_contributo`="' + data.punti_contributo +
      '", `contributo`="' + data.contributo +
      '", `ean`="' + data.ean +
      '", `ean_contributo`="' + data.ean_contributo +
      '", `id_campagna`="' + data.id_campagna +
      '", `id_categoria`="' + data.id_categoria +
      '" WHERE `id`=' + data[this.dataCfg.key];
    // *********************************

    this.isLoading = true;
    this.g.mysql(query)
      .subscribe((res: Result) => {
        this.isLoading = false;
        if (res.result === false) {
          this.message.success('Modifica completata con successo!');
          this.updateEditCache();
        } else {
          this.notification.error('Si è verificato un problema, si prega di riprovare tra qualche minuto.', res.message, { nzDuration: 0 });
          console.log(res);
        }
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notification.error('Si è vericato un errore non previsto.', error.statusText, { nzDuration: 0 });
      });
  }
  // ##############################################
  // DELETE ROW
  deleteRow(id: string): void {
    const index = this.dataSet.findIndex(item => item.id === id);
    const query: string = 'DELETE FROM ' + this.dataCfg.table + ' WHERE `id`= "' + this.dataSet[index].id + '"';
    this.isLoading = true;
    this.g.mysql(query)
      .subscribe((res: Result) => {
        if (res.result === false) {
          this.http.post(this.g.imgHost + 'delete.php', { file: this.dataSet[index].immagine }).subscribe((resDel: any) => {
            console.log(resDel);
          });
          this.isLoading = false;
          this.dataSet.splice(index, 1);
          this.message.success('Cancellazione completata con successo!');
          this.updateEditCache();
        } else {
          this.notification.error('Si è verificato un problema, si prega di riprovare tra qualche minuto.', res.message, { nzDuration: 0 });
          console.log(res);
        }
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notification.error('Si è vericato un errore non previsto.', error.statusText, { nzDuration: 0 });
      });
  }
  // ##############################################
  // FIND CAMPAGNA
  findCampagna(idCampagna: number) {
    const el = this.campagne.find(c => c.id === idCampagna);
    return (typeof el !== 'undefined') ? el.titolo : '(non disponibile)';
  }
  // ##############################################
  // FIND CATEGORIA
  findCategoria(idCategoria: number) {
    const el = this.categorie.find(c => c.id === idCategoria);
    return (typeof el !== 'undefined') ? el.titolo : '(non disponibile)';
  }
  // ##############################################
  // SAVE CHECK
  saveCheck(id: string): void {
    const data: Premio = this.editCache[id].data;
    const index = this.dataSet.findIndex(item => item.id === id);
    this.dataSet[index] = this.editCache[id].data;
    this.editCache[id].edit = false;

    // ********************************* CUSTOMIZE
    data.attivo = !data.attivo;
    const query: string = 'UPDATE ' + this.dataCfg.table +
      ' SET `attivo`=' + ((data.attivo) ? 'true' : 'false') + ' WHERE `id`=' + data.id;
    // *********************************

    this.isLoading = true;

    this.g.mysql(query)
      .subscribe((res: Result) => {
        this.isLoading = false;
        if (res.result === false) {
          this.message.success('Modifica completata con successo!');
          this.updateEditCache();
        } else {
          this.notification.error('Si è verificato un problema, si prega di riprovare tra qualche minuto.', res.message, { nzDuration: 0 });
          console.log(res);
        }
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notification.error('Si è vericato un errore non previsto.', error.statusText, { nzDuration: 0 });
      });
  }
  // ##############################################
  // FIND COLORE
  findColore(idCat: string ) {
    return;
  }
  // ##############################################
  // BEFORE UPLOAD
  beforeUpload = (file: File) => {
    console.log('BEFORE UPLOAD');
    return new Observable((observer: Observer<boolean>) => {
      const isJPG = file.type === 'image/jpeg';
      if (!isJPG) {
        console.error('JPEG ERROR');
        this.message.error('È possibile caricare solamente file JPG.');
        observer.complete();
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < .5;
      if (!isLt2M) {
        console.error('SIZE ERROR');
        this.message.error('L\'immagine deve essere più piccola di 500KB.');
        observer.complete();
        return;
      }
      this.checkImageDimension(file).then(dimensionRes => {
        if (!dimensionRes) {
          console.error('DIMENSION ERROR');
          this.message.error('L\'immagine deve essere di 512x512 pixel.');
          observer.complete();
          return;
        }
        observer.next(isJPG && isLt2M && dimensionRes);
        observer.complete();
      });
    });
  }
  // ##############################################
  // CHECK DIMENSION
  private checkImageDimension(file: File): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        // tslint:disable-next-line:no-non-null-assertion
        window.URL.revokeObjectURL(img.src!);
        resolve(width === height && width === 512);
      };
    });
  }
  // ##############################################
  // HANDLE CHANGE
  handleChange(info: { file: UploadFile }, id: number): void {
    console.log('HANDLE CHANGE', info.file.status, info.file, id);

    switch (info.file.status) {
      case 'uploading':
        console.log('🟡 Upload in corso...');
        this.editCache[id].loading = true;
        break;
      case 'done':
        console.log('✅ Upload completato! File:', info.file);
        const data: Premio = this.editCache[id].data;
        if (typeof data.immagine === 'undefined' || data.immagine === '') {
          data.immagine = 'p' + data.id + '.jpg';
          // ********************************* CUSTOMIZE
          const query: string = 'UPDATE ' + this.dataCfg.table +
            ' SET `immagine`="' + data.immagine + '" WHERE `id`=' + data.id;
          // *********************************
          this.g.mysql(query)
            .subscribe((res: Result) => {
              this.isLoading = false;
              if (res.result === false) {
                this.message.success('Modifica completata con successo!');
                this.updateEditCache();
              } else {
                // tslint:disable-next-line:max-line-length
                this.notification.error('Si è verificato un problema, si prega di riprovare tra qualche minuto.', res.message, { nzDuration: 0 });
                console.log(res);
              }
            }, (error: HttpErrorResponse) => {
              this.isLoading = false;
              this.notification.error('Si è vericato un errore non previsto.', error.statusText, { nzDuration: 0 });
            });
        } else {
          console.log('🔄 Aggiornamento immagine...');
          // REFRESH URL
          data.immagine += '?random+\=' + Math.random();
          this.editCache[id].loading = true;
        }

        this.editCache[id].loading = false;
        break;
      case 'error':
        console.error('❌ Errore di upload:', info.file);
        this.message.error('Errore di collegamento con il server.');
        this.loading = false;
        break;
    }
  }
  // ##############################################
  // CUSTOM REQUEST
  customReq = (item: UploadXHRArgs) => {
    // Create a FormData here to store files and other parameters.
    const formData = new FormData();

    // tslint:disable-next-line:no-any
    formData.append('file', item.file as any);
    formData.append('filename', 'p' + item.name + '.jpg'); // => item.name => data.id

    // tslint:disable-next-line:no-non-null-assertion
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true
    });

    // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
    return this.http.request(req).subscribe(
      // tslint:disable-next-line no-any
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          // tslint:disable-next-line:no-non-null-assertion
          if (event.total! > 0) {
            // tslint:disable-next-line:no-any
            // tslint:disable-next-line:no-non-null-assertion
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          // tslint:disable-next-line:no-non-null-assertion
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          // tslint:disable-next-line:no-non-null-assertion
          item.onSuccess!(event.body, item.file!, event);
        }
      },
      err => {
        // tslint:disable-next-line:no-non-null-assertion
        item.onError!(err, item.file!);
      }
    );
  }
}
